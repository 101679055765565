import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/footer'

import Pic1 from '../img/about/1.jpg'
import Pic3 from '../img/about/3.jpg'
import CV from '../img/about/cv.pdf'

function scrollWin() {
  window.scrollTo(0, 0);
}

class About extends Component {

  componentDidMount = () => this.props.resetScroll()

  render() {
    scrollWin();
    return (
      <div className="wrapper">

        {/* TITLECARD */}
        <div className="titleCardWrapper">
          {/* <h1 className="workNr mainColor">01</h1> */}
          <div className="titleCard">
            <h1>Over</h1>
            <div className="bar"></div>
            <div className="shortInfo">
              <div className="row">
                <p className="title">Locatie</p>
                <p className="info">Nijmegen</p>
              </div>
              <div className="row">
                <p className="title">Educatie</p>
                <p className="info">Communication & Multimedia Design</p>
              </div>
              <div className="row">
                <p className="title">Jaar</p>
                <p className="info">1999</p>
              </div>
            </div>
            <p className="paragraph">Hi! Ik ben Kylian Gieles, 25 jaar oud en geboren en getogen in Nijmegen. Ik zou mezelf omschrijven als een kalme, creatieve en sociale jongen. Ik ben recent afgestudeerd in Communication & Multimedia Design op Avans Hogeschool in ’s-Hertogenbosch. Hier heb ik me ontwikkeld tot een brede designer met focus op branding.</p>
            {/* <a className="cv" download="KylianGieles_CV.pdf" href={CV} >Download CV</a> */}
            <a className="cv" target="_blank" href={CV} >Download CV</a>
          </div>
        </div>

        {/* FIRST IMAGE + BG */}
        <div className="imgBG">
          <div className="imgCon col4">
            <img src={Pic1} alt="mockup" />
          </div>
          <div className="bg col7 mainBG"></div>
        </div>

        {/* OTHER CONTENT */}
        <div className="imgCon col6">
          <img src={Pic3} alt="mockup" />
        </div>

        {/* TEXT AREA */}
        <div className="textArea">
          <blockquote>Don't think in problems, think in solutions</blockquote>
          <p>Zolang ik me kan herinneren heb ik al interesse in het maken van logo’s, websites en video’s. Naast ontwerpen en maken heb ik ook een grote passie voor elektronische muziek en hiphop. Ik ben in mijn vrije tijd dan ook vaak te vinden achter mijn draaitafels. Verder ga ik elke vrijdag met mijn vrienden naar Scouting en speel ik regelmatig een potje pool in de stad. Om mezelf fit te houden doe ik Capoeira en neem ik dagelijks een wandeling.<br></br><br></br>Ik hoop dat je nou een klein beeld hebt van wie ik ben, maar ik zou ook graag met jou kennismaken! Neem gerust contact met me op als je interesse hebt ;)</p>
        </div>

        {/* CARD
        <div className="card col3">
          <div className="title">
            <h1>Want to learn more?</h1>
            <div className="bar barcenter"></div>
          </div>
          <a className="button" target="_blank" href="https://www.nu.nl">view</a>
      </div> */}

        {/* ENDCARD */}
        <div className="endCard">
          <div className="col4">
            <h1>Benieuwd naar wat ik maak?</h1>
            <div className="bar"></div>
            <Link to="/cases">→ Klik hier voor mijn werk</Link>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default About;