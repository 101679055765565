import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

<link href="dist/hamburgers.css" rel="stylesheet"></link>

class Header extends Component {

  state = {
    isMobileNavOpen: false,
  }

  // function test(){
  //   alert("tadaa");
  // }

  render() {
    console.log(this.state.isMobileNavOpen)
    const isOpen = this.state.isMobileNavOpen;
    return (
      <header>
        <div className={isOpen ? "fixed" : "normal"}>
          <div className='logoL'>
            <h1><Link to="/">Kylian Gieles</Link></h1>
          </div>
          <div className='logoS'>
            <h1 onClick={() => this.setState({ isMobileNavOpen: false })}><Link to="/">KG</Link></h1>
          </div>

          <ul className="nav">
            <div className="left">
              <li><NavLink to="/over" extact activeClassName="navActive">Over</NavLink></li>
            </div>
            <div>
              <li><NavLink to="/cases" extact activeClassName="navActive">Cases</NavLink></li>
            </div>
            <div>
              <li><NavLink to="/contact" extact activeClassName="navActive">Contact</NavLink></li>
            </div>
          </ul>

          {/* MOBILE NAV */}
          {/* <div className="hamburgerIcon" onClick={() => this.setState({ isMobileNavOpen: !isOpen })}>
          <div class="bar1"></div>
          <div class="bar2"></div>
          <div class="bar3"></div>
        </div> */}
          <button class={isOpen ? "hamburger hamburger--squeeze is-active hamburgerIcon" : "hamburger hamburger--squeeze hamburgerIcon"} onClick={() => this.setState({ isMobileNavOpen: !isOpen })} >
            <span class="hamburger-box">
              <span class="hamburger-inner"></span>
            </span>
          </button>

          <ul className={isOpen ? "mobileNavOpen mobileNavOpenAnimated" : "mobileNavClosed"}>
            <div>
              <li onClick={() => this.setState({ isMobileNavOpen: !isOpen })}><NavLink to="/over" extact activeClassName="navActive">Over</NavLink></li>
            </div>
            <div>
              <li onClick={() => this.setState({ isMobileNavOpen: !isOpen })}><NavLink to="/cases" extact activeClassName="navActive">Cases</NavLink></li>
            </div>
            <div>
              <li onClick={() => this.setState({ isMobileNavOpen: !isOpen })}><NavLink to="/contact" extact activeClassName="navActive">Contact</NavLink></li>
            </div>
          </ul>
        </div>

      </header>
    );
  }
}

export default Header;