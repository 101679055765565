import React from 'react';
import emailjs from 'emailjs-com';


function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('service_vp94jin', 'template_umdw5ae', e.target, 'user_8iOElfHC8Tt2QLepOQKg4')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });

    window.location.reload();
    alert("Je bericht is verstuurd!");
}


function ContactForm() {

    return (
        <form onSubmit={sendEmail}>
            <label>
                <p>Name</p>
                <input className="short" type="text" name="name" required />
            </label>
            <label>
                <p>E-mail</p>
                <input type="email" name="email" required />
            </label>
            <label>
                <p>Message</p>
                <textarea as="textarea" rows="6" name="message" required>
                </textarea>
            </label>
            <div className="buttonBox">
                <input id="formButton" className="button buttonLeft" type="reset" type="submit" value="Send" required />
            </div>
            {/* onClick="resetForm" */}
        </form>
    );
}

export default ContactForm;