import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import Footer from '../components/footer'

import Cases01 from '../img/cases/01/08.jpg'
import Cases02 from '../img/cases/02/03.jpg'
import Cases03 from '../img/cases/03/08.jpg'
import Cases04 from '../img/cases/04/1.jpg'
import Cases05 from '../img/cases/05/01.jpg'

function scrollWin() {
  window.scrollTo(0, 0);
}

class Cases extends Component {

  componentDidMount = () => this.props.resetScroll()

  render() {
    scrollWin();
    return (
      <div className="wrapper">

        {/* TITLECARD */}
        <div className="titleCardWrapper">
          {/* <h1 className="workNr mainColor">01</h1> */}
          <div className="titleCard">
            <h1>Cases</h1>
            <div className="bar"></div>
            {/* <div className="shortInfo">
            <div className="row">
              <p className="title">Opdracht</p>
              <p className="info">Visuele Identiteit</p>
            </div>
            <div className="row">
              <p className="title">Opdrachtgever</p>
              <p className="info">Avans Hogeschool</p>
            </div>
            <div className="row">
              <p className="title">Jaar</p>
              <p className="info">2019</p>
            </div>
          </div> */}
            <p className="paragraph col3">Hier zijn een aantal van mijn favoriete cases waar ik de afgelopen tijd aan heb gewerkt.</p>
            {/* <Link to="/work/01">Terug naar vorige</Link> */}
          </div>
        </div>

        {/* Case 01 - KBA Nijmegen */}
        <div className="cardWork col3">
          <div className="info">
            <div className="title">
              <h1 className="workNr workColor01">01</h1>
              <h1 className="workColor01">Remedy</h1>
              <div className="bar barcenter workBG01"></div>
            </div>
            <div className="buttonBox">
              <Link to="/cases/01" className="button workColor01">view</Link>
            </div>
          </div>
          <div className="overlay col3 blackBG">
          </div>
          <div className="img col3">
            <img src={Cases01} alt="mockup" />
          </div>
        </div>
        
        {/* Case 02 - REMEDY */}
        <div className="cardWork col3">
          <div className="info">
            <div className="title">
              <h1 className="workNr workColor02">02</h1>
              <h1 className="workColor02">KBA Nijmegen</h1>
              <div className="bar barcenter workBG02"></div>
            </div>
            <div className="buttonBox">
              <Link to="/cases/02" className="button workColor02">view</Link>
            </div>
          </div>
          <div className="overlay col3 blackBG">
          </div>
          <div className="img col3">
            <img src={Cases02} alt="mockup" />
          </div>
        </div>
        
        {/* Case 03 - Wet/Works */}
        <div className="cardWork col3">
          <div className="info">
            <div className="title">
              <h1 className="workNr workColor03">03</h1>
              <h1 className="workColor03">Wet/Works</h1>
              <div className="bar barcenter workBG03"></div>
            </div>
            <div className="buttonBox">
              <Link to="/cases/03" className="button workColor03">view</Link>
            </div>
          </div>
          <div className="overlay col3 blackBG">
          </div>
          <div className="img col3">
            <img src={Cases03} alt="mockup" />
          </div>
        </div>

        {/* Case 04 - Kunstkenners */}
        <div className="cardWork col3">
          <div className="info">
            <div className="title">
              <h1 className="workNr workColor04">04</h1>
              <h1 className="workColor04">Kunstkenners</h1>
              <div className="bar barcenter workBG04"></div>
            </div>
            <div className="buttonBox">
              <Link to="/cases/04" className="button workColor04">view</Link>
            </div>
          </div>
          <div className="overlay col3 blackBG">
          </div>
          <div className="img col3">
            <img src={Cases04} alt="mockup" />
          </div>
        </div>
        
        {/* Case 05 - WAIVE */}
        <div className="cardWork col3">
          <div className="info">
            <div className="title">
              <h1 className="workNr workColor05">05</h1>
              <h1 className="workColor05">WAIVE</h1>
              <div className="bar barcenter workBG05"></div>
            </div>
            <div className="buttonBox">
              <Link to="/cases/05" className="button workColor05">view</Link>
            </div>
          </div>
          <div className="overlay col3 blackBG">
          </div>
          <div className="img col3">
            <img src={Cases05} alt="mockup" />
          </div>
        </div>

        {/* ENDCARD */}
        <div className="endCard">
          <div className="col4">
            <h1>Praten?</h1>
            <div className="bar"></div>
            <Link to="/contact">→ Klik hier om contact op te nemen</Link>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default Cases;