import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../components/footer'

import Pic1 from '../../img/cases/03/001.jpg'
import Pic2 from '../../img/cases/03/002.jpg'
import Pic3 from '../../img/cases/03/03.jpg'
import Pic4 from '../../img/cases/03/04.jpg'
import Pic5 from '../../img/cases/03/05.jpg'
import Pic6 from '../../img/cases/03/06.jpg'
import Pic7 from '../../img/cases/03/07.jpg'
import Pic8 from '../../img/cases/03/08.jpg'

function scrollWin() {
    window.scrollTo(0, 0);
  }

class Cases03 extends Component {

    componentDidMount = () => this.props.resetScroll()

    render() {
        scrollWin();
        return (
            <div className="wrapper">
                {/* TITLECARD */}
                <div className="titleCardWrapper">
                    <h1 className="workNr workColor03">03</h1>
                    <div className="titleCard">
                        <h1>Wet/Works</h1>
                        <div className="bar"></div>
                        <div className="shortInfo">
                            <div className="row">
                                <p className="title">Opdracht</p>
                                <p className="info">Visuele Identiteit</p>
                            </div>
                            <div className="row">
                                <p className="title">Opdrachtgever</p>
                                <p className="info">Wet/Works</p>
                            </div>
                            <div className="row">
                                <p className="title">Jaar</p>
                                <p className="info">2022 - Heden</p>
                            </div>
                        </div>
                        <p className="paragraph">Wet/Works is een nieuw muziekconcept met als doel om de nieuwe minimal-house sound naar Nijmegen te brengen en lokale artiesten die actief zijn binnen deze stijl een podium bieden. Omdat deze diepere stijl had nog geen thuis had in Nijmeegse events, hebben ze mij gevraagd om een logo en huisstijl voor Wet/Works te ontwerpen waarmee ze hun events kunnen promoten.</p>
                        <Link to="/cases/02" className="previous">Terug naar vorige</Link>
                    </div>
                </div>

                {/* FIRST IMAGE + BG */}
                <div className="imgBG">
                    <div className="imgCon col7">
                        <img src={Pic1} alt="mockup" />
                    </div>
                    <div className="bg col10 workBG03"></div>
                </div>

                {/* IMG 2 */}
                <div className="imgCon col7">
                    <img src={Pic2} alt="mockup" />
                </div>
                {/* IMG 3 */}
                {/* <div className="imgCon col7">
                    <img src={Pic3} alt="mockup" />
                </div> */}
                {/* IMG 4 */}
                <div className="imgCon col6">
                    <img src={Pic4} alt="mockup" />
                </div>

                {/* TEXT AREA */}
                <div className="textArea">
                    <blockquote>De huisstijl is doorweekt met druppels</blockquote>
                    <p>Wet/Works was op zoek naar een uiterlijk wat dit nieuwe genre ook zou uitstralen. Dit resulteerde in een modern en minimalistisch uiterlijk waarbij een kalme donkerblauwe kleur en water de leidraad voeren. Door de strakke en met druppels doorweekte huisstijl vallen ze zonder twijfel op in de muziekscene.</p>
                </div>

                {/* IMG 5 + BG */}
                <div className="imgBG">
                    <div className="imgCon col10">
                        <img src={Pic5} alt="mockup" />
                    </div>
                    <div className="bg col7 workBG03"></div>
                </div>
                {/* IMG 6 */}
                <div className="imgCon col6">
                    <img src={Pic6} alt="mockup" />
                </div>

                {/* CARD */}
                {/* <div className="card col3">
                    <div className="title">
                        <h1>Bekijk de website!</h1>
                        <div className="bar barcenter"></div>
                    </div>
                    <div className="buttonBox">
                        <a className="button" target="_blank" href="https://www.djtrovao.com">view</a>
                    </div>
                </div> */}

                {/* IMG 7 + BG */}
                <div className="imgBG">
                    <div className="imgCon col5">
                        <img src={Pic7} alt="mockup" />
                    </div>
                    <div className="bg col10 workBG03"></div>
                </div>
                {/* IMG 8 */}
                <div className="imgCon col7">
                    <img src={Pic8} alt="mockup" />
                </div>

                {/* ENDCARD */}
                <div className="endCard">
                    <div className="col4">
                        <h1>Iets anders?</h1>
                        <div className="bar"></div>
                        <Link to="/cases/04">→ Klik hier voor het volgende project</Link>
                    </div>
                    <Footer />
                </div>
            </div>
        )
    }
}

export default Cases03;