import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../components/footer'

import Pic1 from '../../img/cases/05/01.jpg'
import Pic2 from '../../img/cases/05/02.mp4'
import Pic3 from '../../img/cases/05/03.jpg'
import Pic4 from '../../img/cases/05/04.jpg'
import Pic5 from '../../img/cases/05/05.jpg'

function scrollWin() {
  window.scrollTo(0, 0);
}

class Cases05 extends Component {

  componentDidMount = () => this.props.resetScroll()

  render() {
    scrollWin();
    return (
      <div className="wrapper">
        {/* TITLECARD */}
        <div className="titleCardWrapper">
          <h1 className="workNr workColor05">05</h1>
          <div className="titleCard">
            <h1>WAIVE</h1>
            <div className="bar"></div>
            <div className="shortInfo">
              <div className="row">
                <p className="title">Opdracht</p>
                <p className="info">UI/UX</p>
              </div>
              <div className="row">
                <p className="title">Opdrachtgever</p>
                <p className="info">Thunderboom Records</p>
              </div>
              <div className="row">
                <p className="title">Jaar</p>
                <p className="info">2022</p>
              </div>
            </div>
            <p className="paragraph">Thunderboom Records is een stichting die zich focust op AI-gedreven muziektools en virtuele artiesten. Samen met Beeld & Geluid hebben zij WAIVE gecreëerd: een smart DJ-systeem dat doormiddel van Artificial Intelligence de geluidsarchieven van Beeld & Geluid op muzikale potentie analyseert en zo unieke samples en beats kan maken. Mijn projectgroep en ik hebben voor hen de UI/UX van de tool ontworpen.</p>
            <Link to="/cases/04" className="previous">Terug naar vorige</Link>
          </div>
        </div>

        {/* FIRST IMAGE + BG */}
        <div className="imgBG">
          <div className="imgCon col7">
            <img src={Pic1} alt="mockup" />
          </div>
          <div className="bg col10 workBG05"></div>
        </div>

        {/* IMG 02 */}
        <div className="imgCon col8">
          <video src={Pic2} alt="mockup" autoPlay loop muted/>
        </div>

        {/* TEXT AREA */}
      <div className="textArea">
        <blockquote>Als ik dit zie, krijg ik meteen zin om het te gebruiken!</blockquote>
        <p>Omdat WAIVE voor zowel ervaren als onervaren muzikanten te gebruiken moest zijn, hebben we tijdens het proces ook user-tests uitgevoerd bij DJ-studentenvereniging SoundLab Nijmegen. Dit heeft er voor gezorgd dat we een intuïtieve interface hebben ontworpen met unieke functies en drumsequencer. Het is heel makkelijk om nieuwe samples te zoeken, bewerken of toe te voegen en verwijderen.</p>
      </div>

        {/* IMG 03 */}
        <div className="imgCon col6">
          <img src={Pic3} alt="mockup" />
        </div>

        {/* CARD */}
        <div className="card col3">
          <div className="title">
            <h1>Ontdek het prototype</h1>
            <div className="bar barcenter"></div>
          </div>
          <div className="buttonBox">
            <a className="button" target="_blank" href="https://xd.adobe.com/view/ce73ab38-6101-4e50-a96f-aafcbb8340bb-9b20/?fullscreen">view</a>
          </div>
        </div>

        {/* IMG 04 + BG */}
        <div className="imgBG">
          <div className="imgCon col6">
            <img src={Pic4} alt="mockup" />
          </div>
          <div className="bg col9 workBG05"></div>
        </div>

        {/* IMG 05 */}
          <div className="imgCon col6">
          <img src={Pic5} alt="mockup" />
        </div>

        {/* ENDCARD */}
        <div className="endCard">
          <div className="col4">
            <h1>Overtuigd?</h1>
            <div className="bar"></div>
            <Link to="/contact">→ Klik hier om contact op te nemen</Link>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default Cases05;