import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../components/footer'

import Pic1 from '../../img/cases/02/01.jpg'
import Pic2 from '../../img/cases/02/02.jpg'
import Pic3 from '../../img/cases/02/03.jpg'
import Pic4 from '../../img/cases/02/04.jpg'
import Pic5 from '../../img/cases/02/05.jpg'

function scrollWin() {
  window.scrollTo(0, 0);
}

class Cases02 extends Component {

  componentDidMount = () => this.props.resetScroll()

  render() {
    scrollWin();
    return (
      <div className="wrapper">
        {/* TITLECARD */}
        <div className="titleCardWrapper">
          <h1 className="workNr workColor02">02</h1>
          <div className="titleCard">
            <h1>KBA Nijmegen</h1>
            <div className="bar"></div>
            <div className="shortInfo">
              <div className="row">
                <p className="title">Opdracht</p>
                <p className="info">Logo redesign & website</p>
              </div>
              <div className="row">
                <p className="title">Opdrachtgever</p>
                <p className="info">KBA Nijmegen</p>
              </div>
              <div className="row">
                <p className="title">Jaar</p>
                <p className="info">2021</p>
              </div>
            </div>
            <p className="paragraph">KBA Nijmegen is een toonaangevend onderzoeksinstituut op het gebied van onderwijs, de arbeidsmarkt en de aansluiting hiertussen. Tijdens mijn stage bij BYRON is mij gevraagd om een redesign van het logo te maken, waarbij ik dichtbij het bestaande imago van KBA Nijmegen moest blijven. Het nieuwe logo moest professioneel, deskundig, betrouwbaar en herkenbaar zijn tegenover het oude logo. De ‘i’ van informatie en de menselijke vorm moesten dus terugkomen.</p>
            <Link to="/cases/01" className="previous">Terug naar vorige</Link>
          </div>
        </div>

        {/* FIRST IMAGE + BG */}
        <div className="imgBG">
          <div className="imgCon col7">
            <img src={Pic1} alt="mockup" />
          </div>
          <div className="bg col10 workBG02"></div>
        </div>

        {/* IMG 02 */}
        <div className="imgCon col6">
          <img src={Pic2} alt="mockup" />
        </div>

        {/* TEXT AREA */}
        <div className="textArea">
          <blockquote>Dit is precies waar we naar zochten!</blockquote>
          <p>Naast een nieuw logo heb ik ook enkele toepasselijke uitingen ontworpen zoals visitekaartjes en voorpagina’s voor de onderzoeksrapporten. Ook kon de verouderde website wel een update gebruiken, waarbij overzicht centraal stond.</p>
        </div>

        {/* IMG 03 + BG */}
        <div className="imgBG">
          <div className="imgCon col6">
            <img src={Pic3} alt="mockup" />
          </div>
          <div className="bg col8 workBG02"></div>
        </div>
        {/* IMG 04 */}
        <div className="imgCon col6">
          <img src={Pic5} alt="mockup" />
        </div>
        {/* IMG 05 */}
        <div className="imgCon col6">
          <img src={Pic4} alt="mockup" />
        </div>

        {/* CARD
        <div className="card col3">
          <div className="title">
            <h1>Want to learn more?</h1>
            <div className="bar barcenter"></div>
          </div>
          <div className="buttonBox">
            <a className="button" target="_blank" href="https://www.nu.nl">view</a>
          </div>
      </div> */}

        {/* ENDCARD */}
        <div className="endCard">
          <div className="col4">
            <h1>Nog een?</h1>
            <div className="bar"></div>
            <Link to="/cases/03">→ Klik hier voor het volgende project</Link>
          </div>
          <Footer />
        </div>
      </div>
    )
  }
}

export default Cases02;