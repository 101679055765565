import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../components/footer'

import Pic1 from '../../img/cases/04/1.jpg'
import Pic2 from '../../img/cases/04/2.jpg'
import Pic3 from '../../img/cases/04/3.jpg'
import Pic4 from '../../img/cases/04/4.jpg'
import Pic5 from '../../img/cases/04/5.jpg'
import Print from '../../img/cases/04/print.pdf'

function scrollWin() {
  window.scrollTo(0, 0);
}

class Cases04 extends Component {

  componentDidMount = () => this.props.resetScroll()

  render() {
    scrollWin();
    return (
      <div className="wrapper">
        {/* TITLECARD */}
        <div className="titleCardWrapper">
          <h1 className="workNr workColor04">04</h1>
          <div className="titleCard">
            <h1>Kunstkenners</h1>
            <div className="bar"></div>
            <div className="shortInfo">
              <div className="row">
                <p className="title">Opdracht</p>
                <p className="info">Alternatief voor Museumbezoek</p>
              </div>
              <div className="row">
                <p className="title">Opdrachtgever</p>
                <p className="info">Het Noordbrabants Museum</p>
              </div>
              <div className="row">
                <p className="title">Jaar</p>
                <p className="info">2021</p>
              </div>
            </div>
            <p className="paragraph">Het Noordbrabants Museum presenteert kunst, cultuur en geschiedenis van Noord-Brabant. Door covid-19 is museumbezoek met complete klassen erg lastig. Daarom hebben ze voor het basisonderwijs en de onderbouw van het voortgezet onderwijs 'Museum in de klas' ontwikkeld. Ons is gevraagd om een intrigerende en interactieve ‘Museum in de klas' te ontwikkelen voor de bovenbouw van het voortgezet onderwijs.</p>
            <Link to="/cases/03" className="previous">Terug naar vorige</Link>
          </div>
        </div>

        {/* FIRST IMAGE + BG */}
        <div className="imgBG">
          <div className="imgCon col6">
            <img src={Pic1} alt="mockup" />
          </div>
          <div className="bg col8 workBG04"></div>
        </div>

        {/* OTHER CONTENT */}
        <div className="imgCon col6">
          <img src={Pic2} alt="mockup" />
        </div>

        {/* TEXT AREA */}
        <div className="textArea">
          {/* <blockquote>Als die discussies goed zijn dan is het ten eerste heel leuk, maar daarnaast ook leerzaam.</blockquote> */}
          {/* <p className="italic">"Het Schildersgilde is bijeen gekomen, maar er is een Vervalser in jullie midden! Aan de hand van een vragenronde van twee minuten proberen de spelers erachter te komen wie er niet thuishoort in het gilde, terwijl de Vervalser probeert erachter te komen over welk schilderij jullie het hebben.</p> */}
          <p className="italic">"De Kunstkenners zijn bijeengekomen om een schilderij te bespreken, maar er is een Cultuur-barbaar in het midden die niet weet welk schilderij er besproken wordt. In een discussieronde van twee minuten stellen de spelers vragen over het schilderij aan elkaar. De Kunstkenners proberen erachter te komen wie de Cultuurbarbaar is, terwijl de Cultuurbarbaar probeert erachter te komen welk schilderij er besproken wordt. Aan het eind van de discussierondes wordt er gestemd."<br></br><br></br><br></br></p>
          <p>Het spel is ontwikkeld met een paar doelen als uitgangspunt: Intrigeren van de doelgroep, ze beter te laten kijken naar de kunstwerken en interactie en samenwerking tussen leerlingen stimuleren. Het spel maakt gebruik van kaarten, schilderijtableaus en een mobiele app waarmee onder andere de rollen verdeeld worden en gestemd wordt. Door middel van play-testing hebben we de regels verfijnd en het spel ontwikkeld tot een leuke en speelbare ervaring.</p>
        </div>

        {/* OTHER CONTENT */}
        <div className="imgBG">
          <div className="imgCon col7">
            <img src={Pic4} alt="mockup" />
          </div>
          <div className="bg col8 workBG04"></div>
        </div>

        {/* CARD */}
        <div className="card col3">
          <div className="title">
            <h1>DIY versie spelen?</h1>
            <div className="bar barcenter"></div>
          </div>
          <div className="buttonBox">
            <a className="button" target="_blank" href={Print} >view</a>
          </div>
        </div>

        {/* TEXT AREA */}
        <div className="textArea">
          <blockquote>Als de discussies goed zijn dan is het ten eerste heel leuk, maar daarnaast ook leerzaam.</blockquote>
          <p>- Het Noordbrabants Museum</p>
          {/* <p>Ik hoop dat je nou een klein beeld hebt van wie ik ben, maar ik zou ook graag met jou kennismaken! Neem gerust contact met me op als je interesse hebt ;)</p> */}
        </div>

        {/* OTHER CONTENT */}
        <div className="imgBG">
          <div className="imgCon col6">
            <img src={Pic5} alt="mockup" />
          </div>
          <div className="bg col8 workBG04"></div>
        </div>
        <div className="imgCon col7">
          <img src={Pic3} alt="mockup" />
        </div>

        {/* ENDCARD */}
        <div className="endCard">
          <div className="col4">
            <h1>Verder kijken?</h1>
            <div className="bar"></div>
            <Link to="/cases/05">→ Klik hier voor het volgende project</Link>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default Cases04;