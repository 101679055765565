import React, { useRef } from 'react';
import {
  HashRouter as Router,
  Route,
  Switch, Redirect
} from 'react-router-dom';

//global components
import Nav from './components/nav';

//pages
import Home from './pages/home';
import About from './pages/about';
import Cases from './pages/cases';
import Contact from './pages/contact';

//casesPages
import Cases01 from './pages/cases/01';
import Cases02 from './pages/cases/02';
import Cases03 from './pages/cases/03';
import Cases04 from './pages/cases/04';
import Cases05 from './pages/cases/05';

import './styles/scss/style.scss';


function App() {
  const wrapper = useRef(null)
  const resetScroll = () => wrapper.current?.scrollTo(0, 0)

  return (
    <Router>
      <div className="App">
        <Nav />
        <div className="pagesWrapper" ref={wrapper}>
          <Switch onUpdate={() => wrapper.current.scrollTo(0, 0)} >
            {/* <Route exact path='/' component={Home} /> */}
            <Route exact path="/" render={() => <Home resetScroll={resetScroll} />} />
            <Route exact path="/portfolio" render={() => <Home resetScroll={resetScroll} />} />
            <Route exact path="/over" render={() => <About resetScroll={resetScroll} />} />
            <Route exact path="/cases" render={() => <Cases resetScroll={resetScroll} />} />
            <Route exact path="/contact" render={() => <Contact resetScroll={resetScroll} />} />
            <div>
              {/* Cases Pages */}
              <Route exact path="/cases/01" render={() => <Cases01 resetScroll={resetScroll} />} />
              <Route exact path="/cases/02" render={() => <Cases02 resetScroll={resetScroll} />} />
              <Route exact path="/cases/03" render={() => <Cases03 resetScroll={resetScroll} />} />
              <Route exact path="/cases/04" render={() => <Cases04 resetScroll={resetScroll} />} />
              <Route exact path="/cases/05" render={() => <Cases05 resetScroll={resetScroll} />} />
            </div>
            <Redirect to='/' />
          </Switch>
        </div>
      </div>
    </Router>
  );
}

export default App;