import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../components/footer'

import Pic1 from '../../img/cases/01/01.jpg'
import Pic2 from '../../img/cases/01/02.jpg'
import Pic3 from '../../img/cases/01/03.jpg'
import Pic4 from '../../img/cases/01/04.jpg'
import Pic5 from '../../img/cases/01/05.jpg'
import Pic6 from '../../img/cases/01/06.jpg'
import Pic7 from '../../img/cases/01/07.jpg'
import Pic8 from '../../img/cases/01/08.jpg'

function scrollWin() {
  window.scrollTo(0, 0);
}

class Cases01 extends Component {

  componentDidMount = () => this.props.resetScroll()

  render() {
    scrollWin();
    return (
      <div className="wrapper">
        {/* TITLECARD */}
        <div className="titleCardWrapper">
          <h1 className="workNr workColor01">01</h1>
          <div className="titleCard">
            <h1>Remedy</h1>
            <div className="bar"></div>
            <div className="shortInfo">
              <div className="row">
                <p className="title">Opdracht</p>
                <p className="info">Visuele Identiteit</p>
              </div>
              <div className="row">
                <p className="title">Opdrachtgever</p>
                <p className="info">Remedy</p>
              </div>
              <div className="row">
                <p className="title">Jaar</p>
                <p className="info">2022-heden</p>
              </div>
            </div>
            <p className="paragraph">Remedy is een nieuw muziekconcept van Nijmeegse bodem, waarbij het genre bass-house centraal staat. Mij is gevraagd hen te voorzien van een logo en huisstijl, waar met veel verschillende aspecten rekening gehouden moest worden. Met ambities voor grotere internationale acts en feesten was het belangrijk om een huisstijl te maken die hier ook bij zou passen en dit zou aantrekken.</p>
            {/* <Link to="/cases/01" className="previous">Terug naar vorige</Link> */}
          </div>
        </div>

        {/* FIRST IMAGE + BG */}
        <div className="imgBG">
          <div className="imgCon col7">
            <img src={Pic1} alt="mockup" />
          </div>
          <div className="bg col10 workBG01"></div>
        </div>

        {/* IMG 2 */}
        <div className="imgCon col7">
          <img src={Pic2} alt="mockup" />
        </div>
        {/* IMG 3 */}
        <div className="imgCon col6">
          <img src={Pic3} alt="mockup" />
        </div>
        
        {/* TEXT AREA */}
        <div className="textArea">
          <blockquote>Het is ons nu al gelukt om een internationale act te boeken!</blockquote>
          <p>Het logo moest een prettige rave ervaring overbrengen, die zowel fris en toegankelijk voelt, maar tegelijkertijd ook stoer en donker. Met unieke kleuren en het opvallende logo is een sterke visuele identiteit ontstaan. Hiermee is het Remedy gelukt om voor hun tweede editie al een grote internationale act te boeken!</p>
        </div>

        {/* CARD */}
        {/* <div className="card col3">
          <div className="title">
          <h1>Bekijk de styleguide</h1>
          <div className="bar barcenter"></div>
          </div>
          <div className="buttonBox">
          <a className="button" href={Proces} target="_blank">bekijk</a>
          </div>
        </div> */}


       {/* IMG 4 + BG */}
        <div className="imgBG">
          <div className="imgCon col10">
            <img src={Pic4} alt="mockup" />
          </div>
          <div className="bg col7 workBG01"></div>
        </div>

        {/* IMG 5 */}
        <div className="imgCon col4">
          <img src={Pic5} alt="mockup" />
        </div>

        {/* IMG 6 + BG */}
        <div className="imgBG">
          <div className="imgCon col6">
            <img src={Pic6} alt="mockup" />
          </div>
          <div className="bg col10 workBG01"></div>
         </div>
        {/* IMG 7 */}
        <div className="imgCon col6">
          <img src={Pic7} alt="mockup" />
        </div>
        {/* IMG 7 */}
          <div className="imgCon col6">
          <img src={Pic8} alt="mockup" />
        </div>

        {/* ENDCARD */}
        <div className="endCard">
          <div className="col4">
            <h1>Meer zien?</h1>
            <div className="bar"></div>
            <Link to="/cases/02">→ Klik hier voor het volgende project</Link>
          </div>
          <Footer />
        </div>
      </div>
    )
  }
}

export default Cases01;