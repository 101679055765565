import React, { Component } from 'react';
import ContactForm from '../components/contactForm';
import Footer from '../components/footer'

function scrollWin() {
  window.scrollTo(0, 0);
}

class Contact extends Component {

  componentDidMount = () => this.props.resetScroll()

  render() {
    scrollWin();
    return (
      <div className="wrapper">

        {/* TITLECARD */}
        <div className="titleCardWrapper">
          {/* <h1 className="workNr mainColor">01</h1> */}
          <div className="titleCard">
            <h1>Contact</h1>
            <div className="bar"></div>
            <div className="shortInfo">
              <div className="row">
                <p className="title">E-mail</p>
                <a className="info" href="mailto:contact@kyliangieles.nl">contact@kyliangieles.nl</a>
              </div>
              <div className="row">
                <p className="title">Tel. nummer</p>
                <p className="info">+31 6 12829896</p>
              </div>
              <div className="row">
                <p className="title">KvK</p>
                <a className="info">91233070</a>
              </div>
              <div className="row">
                <p className="title">LinkedIn</p>
                <a className="info" href="https://www.linkedin.com/in/kyliangieles/" target="_blank">/in/kyliangieles</a>
              </div>
            </div>
            <p className="paragraph">Heb je iets gezien dat je interesse heeft gewekt? Schroom niet om contact met me op te nemen! Ik ben te bereiken via het contactformulier en de kanalen hierboven. Ik reageer doorgaans binnen 24 uur.</p>
            {/* <a className="cv" download="KylianGieles_CV.pdf" href={CV} >Download CV</a> */}
          </div>
        </div>

        {/* ENDCARD */}
        <div className="endCard">
          <div className="col4">
            <h1>Let's talk!</h1>
            <div className="bar"></div>
            {/* <Link to="/work">→ Klik hier voor mijn werk</Link> */}
            <ContactForm />
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;